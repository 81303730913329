/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from 'react';
import {CheckboxList} from '../CheckboxList/CheckboxList';
import * as _ from 'lodash';
import {withGlobals} from '../../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../../galleryGlobalStrategy';

export interface IMultipleSelectionFilterProps extends IGalleryGlobalProps {
  options: {id: string; name: string}[];
  filterId: number;
  selected: string[];
}

class MultipleSelectionFilterComp extends React.Component<IMultipleSelectionFilterProps> {
  private handleChange(e) {
    const selectedOptionId = e.target.value;
    this.props.globals.filterProducts(this.props.filterId, selectedOptionId);
  }

  public shouldComponentUpdate(nextProps: IMultipleSelectionFilterProps) {
    return !_.isEqual(this.props, nextProps);
  }

  public render() {
    const {
      options,
      selected,
      filterId,
      globals: {compId},
    } = this.props;

    return (
      <CheckboxList
        id={`${filterId}${compId}`}
        selected={selected}
        options={options}
        onChange={(e) => this.handleChange(e)}
        isCategoryPage={this.props.globals.isCategoryPage}
      />
    );
  }
}

export const MultipleSelectionFilter = withGlobals(MultipleSelectionFilterComp);
